/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`Methycobal Thailand`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >
      <meta
        name="keywords"
        content="Methycobal, เมทิโคบอล, Mecobalamin, นิวโรเบียน  , Neurobion , ดีบูน , Alinamin  , ป้องกันอัลไซเมอร์, เสริมภูมิคุ้มกัน, ซ่อมแซมเส้นประสาท, Active วิตามิน B12, ระบบประสาทสมอง, ป้องกันโลหิตจาง, สร้างเม็ดเลือดแดง   , รักษาอาการชา, บรรเทา อาการปลายประสาทอักเสบ, เหน็บชา, ชาปลายมือปลายเท้า, ชาเฉียบพลัน, ขาดวิตามินบี 12, ทำไม มือชา, กดทับเส้นประสาท, ชาเป็นแถบๆ, ชาตามนิ้ว"
      />
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
