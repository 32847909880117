import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronUp } from "@fortawesome/free-solid-svg-icons"

import Header from "./header"
// import "./layout.css"
// import "./methycobal-style.css"
// import "./responsive.css"

// import "./methycobal-script"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div id="app">
      <Header siteTitle={data.site.siteMetadata.title} />
      <a href="#intro" className="gw goto-top fade-out" id="gotoTop">
        <FontAwesomeIcon icon={faChevronUp} />
      </a>
      <div
        style={{
          padding: 0,
          paddingTop: 0,
        }}
      >
        <main>{children}</main>
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
