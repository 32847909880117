import React, { useState } from "react"
import Recaptcha from "react-recaptcha"
import axios from "axios"
import Modali, { useModali } from "modali"
import validate from "validate.js"

const FORM_VALIDATE = {
  name: { presence: { allowEmpty: false, message: "กรุณากรอกชื่อและนามสกุล" } },
  email: {
    presence: { allowEmpty: false, message: "กรุณากรอกอีเมล์" },
    email: { message: "กรุณากรอกอีเมล์ในฟอร์แมทที่ถูกต้อง" },
  },
  telephone: {
    presence: true,
    format: {
      pattern: "^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-s./0-9]*$",
      message: "กรุณากรอกเบอร์โทรศัพท์ที่ถูกต้อง",
    },
    length: {
      minimum: 10,
      maximum: 12,
      tooShort: "กรุณากรอกเบอร์โทรศัพท์ที่ถูกต้อง",
      tooLong: "กรุณากรอกเบอร์โทรศัพท์ที่ถูกต้อง",
    },
  },
  detail: { presence: { allowEmpty: false, message: "กรุณากรอกรายละเอียด" } },
}

const SectionContact = () => {
  const defaultState = {
    name: "",
    email: "",
    telephone: "",
    detail: "",
  }
  const recaptchaRef = React.createRef()
  const sitekey = "6LeZeb4UAAAAAD5Dp9htVN9gv9jL6QxJ7MRGTPgY"
  const [contact, setContact] = useState(defaultState)
  const [formState, setFormState] = useState({ errorMessage: null })
  const [errorModal, toggleErrorModal] = useModali({
    animated: true,
    title: "เกิดความผิดพลาด",
    buttons: [
      <Modali.Button
        label="OK"
        isStyleDefault
        onClick={() => toggleErrorModal()}
      />,
    ],
  })
  const [successModal, toggleSuccessModal] = useModali({
    animated: true,
    title: "บันทึกข้อมูลสำเร็จ",
    buttons: [
      <Modali.Button
        label="OK"
        isStyleDefault
        onClick={() => toggleSuccessModal()}
      />,
    ],
  })

  const handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name

    setContact({
      ...contact,
      [name]: value,
    })
  }

  const handleSubmit = event => {
    event.preventDefault()
    const recaptchaValue = recaptchaRef.current.getValue()
    if (!recaptchaValue) {
      setFormState({ errorMessage: "กรุณายืนยันตัวตนผ่าน ReCaptcha" })
      toggleErrorModal()
      return
    }

    const validateResult = validate(contact, FORM_VALIDATE)
    if (typeof validateResult !== "undefined") {
      const errors = []
      Object.keys(validateResult).forEach(f => {
        if (validateResult[f].length > 0)
          errors.push(
            <div>
              {validateResult[f]
                .pop()
                .split(" ")
                .pop()}
            </div>
          )
      })
      setFormState({ errorMessage: errors })
      toggleErrorModal()
      return
    }

    axios({
      method: "post",
      url:
        "https://asia-northeast1-methycobal-5c0fa.cloudfunctions.net/api/contacts",
      data: contact,
    })
      .then(function() {
        toggleSuccessModal()
        setContact(defaultState)
      })
      .catch(function(response) {
        console.error(response)
        setFormState({ errorMessage: "กรุณากรอกข้อมูลให้ครบ" })
        toggleErrorModal()
      })
  }

  return (
    <section className="box-contact pb-5 pt-5 defered-bg" id="contact">
      <div className="col-md-7 col-sm-6 offset-sm-2 px-5 py-5 body-address">
        <h2>กรอกข้อมูลเพื่อติดต่อ</h2>
        <form className="row" onSubmit={handleSubmit}>
          <div className="col-12 mb-3">
            <input
              type="text"
              name="name"
              className="form-control"
              id="name"
              placeholder="ชื่อ - นามสกุล"
              onChange={handleInputChange}
              defaultValue={contact.name}
            />
          </div>
          <div className="col-12 mb-3">
            <input
              type="email"
              name="email"
              className="form-control"
              id="email"
              placeholder="อีเมล์"
              onChange={handleInputChange}
              defaultValue={contact.email}
            />
          </div>
          <div className="col-12 mb-3">
            <input
              type="tel"
              name="telephone"
              className="form-control"
              id="phonenumber"
              placeholder="เบอร์โทรศัพท์"
              onChange={handleInputChange}
              defaultValue={contact.telephone}
            />
          </div>
          <div className="col-12 mb-3">
            <textarea
              placeholder="รายละเอียดเรื่องที่สอบถาม"
              name="detail"
              className="form-control"
              id="detail_contact"
              rows="5"
              onChange={handleInputChange}
              defaultValue={contact.detail}
            ></textarea>
          </div>
          <div className="col-lg-4 col-sm-12">
            <input
              id="btn-sendmail"
              className="btn btn-green"
              type="submit"
              value="ส่งรายละเอียด"
            />
          </div>
          <div className="col-lg-3 col-sm-12">
            <Recaptcha
              className="captcha"
              ref={recaptchaRef}
              sitekey={sitekey}
            />
          </div>
        </form>
      </div>
      <Modali.Modal {...errorModal}>{formState.errorMessage}</Modali.Modal>
      <Modali.Modal {...successModal}>
        รับข้อมูลเข้าสู่ระบบเรียบร้อย
      </Modali.Modal>
    </section>
  )
}

export default SectionContact
