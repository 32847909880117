import React from "react"
import { LazyLoadImage } from "react-lazy-load-image-component"

const SectionProduct = () => (
  <section className="box-product pt-5 pb-5" id="product">
    <div className="container">
      <div className="row">
        <div className="col-lg-6 align-self-end">
          <h1 className="text-center">
            <span className="header-methycobal">ผลิตภัณฑ์เมทิโคบอล</span>
          </h1>
          <p className="text-center caption">
            ใช้รักษาโรคของระบบประสาทส่วนปลาย
            <br className="br-sm" /> ซึ่งเป็นสาเหตุหนึ่งของอาการชา
            <br />
            รับประทานครั้งละ 1 เม็ด วันละ 3 ครั้ง
          </p>
        </div>
        <div className="col-lg-6">
          <LazyLoadImage
            src="picproduct.jpg"
            loading="lazy"
            className="img-fluid pic-product"
            alt="Methycobal"
          />
        </div>
      </div>
      <div className="row detail-methycobal">
        <div className="col-lg-6">
          <p className="justify-content-between">
            <span className="detail-special">
              <strong className="font-bold">เมทิโคบอล</strong> เป็นผลิตภัณฑ์
              Mecobalamin ซึ่งบริษัท เอไซ จำกัด ได้พัฒนาขึ้นมา โดยอาศัย
            </span>
            เทคโนโลยีต้นแบบของบริษัท
            เพื่อใช้เป็นยาสำหรับรักษาโรคของระบบประสาทส่วนปลาย&nbsp;
            <br className="br-ex onespan" />
            ในแง่ของชีวเคมี Mecobalamin เกี่ยวข้องกับปฏิกิริยา Transmethylation
            ในฐานะที่เป็นวิตามิน บี 12 ซึ่งประกอบด้วยโคเอนไซม์
            <br className="br-nm" />
            ที่ปรากฏอยู่ในเลือด
          </p>
          <div className="media">
            <i className="icon icon-indication"></i>
            <div className="media-body">
              <h3 className="font-bold">ข้อบ่งใช้/ Indication</h3>
              <p className="justify-content-between">
                รักษาโรคของระบบประสาทส่วนปลาย/
                <br className="br-sm br-md" /> Peripheral Neuropathies
              </p>
            </div>
          </div>
          <div className="media">
            <i className="icon icon-composition"></i>
            <div className="media-body">
              <h3 className="font-bold">ส่วนประกอบ/ Composition</h3>
              <p className="justify-content-between">
                ยาเม็ดเคลือบน้ำตาลสีขาว ประกอบด้วย มีโคบาลามิน
                <br className="br-md" /> 500 ไมโครกรัมต่อเม็ด/
                <br />
                White sugar-coated tablet contains 500 microgram of mecobalamin
                <br className="br-ex" />
                per tablet
              </p>
            </div>
          </div>
          <div className="media">
            <i className="icon icon-dosage"></i>
            <div className="media-body">
              <h3 className="font-bold">
                ขนาดการรักษา/ Dosage and administration
              </h3>
              <p className="justify-content-between">
                ขนาดยาปกติสำหรับผู้ใหญ่ : รับประทานครั้งละ 1 เม็ด วันละ 3 ครั้ง
                <br className="br-nm" /> (มีโคบาลามิน 1,500 ไมโครกรัม){" "}
                ขนาดของยาควรปรับตามอายุและความรุนแรงของอาการ/
                <br />
                Usually for adults, orally administrater 1 tablet 3 times a day
                (1,500 <br className="br-ex" />
                microgram of mecobalamin),
                <br className="br-md" /> The dosage should be adjusted according{" "}
                <br className="br-ex" />
                to age and severity of symptoms.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="media">
            <i className="icon icon-packing"></i>
            <div className="media-body">
              <h3 className="font-bold">ขนาดบรรจุ/ Packing</h3>
              <p className="justify-content-between">
                กล่องละ 3 แผง และ 300 แผง (แผงละ 10 เม็ด)/
                <br />
                Box of 3 strip sheets x 10 tablets and box of 300 strip sheets x
                10 tablets
              </p>
            </div>
          </div>
          <div className="media">
            <i className="icon icon-storage"></i>
            <div className="media-body">
              <h3 className="font-bold">การเก็บรักษา/ Storage</h3>
              <p className="justify-content-between">
                ควรเก็บเมทิโคบอลที่อุณหภูมิห้องซึ่งไม่เกิน 30 องศาเซลเซียส
                ป้องกันจากแสงและความชื้น หลังเปิดใช้ <br className="br-md" />
                (แสงจะทำให้ปริมาณตัวยาลดลงและเม็ดยาอาจเปลี่ยนเป็นสีแดง
                เมื่อสัมผัสกับความชื้น)/
                <br />
                Methycobal should be stored at room temperature not exceeding 30
                degree celsius , protected from light and moisture after
                unsealing. (Light decreases the content and tablets may turn
                reddish with exposure to moisture)
              </p>
            </div>
          </div>
          <div className="media">
            <i className="icon icon-expiration-date"></i>
            <div className="media-body">
              <h3 className="font-bold">วันหมดอายุ/ Expiration date</h3>
              <p className="justify-content-between">
                ควรใช้เมทิโคบอลก่อนวันหมดอายุที่แสดงอยู่บนภาชนะบรรจุ/
                <br />
                Methycobal should be used before th expiration date indicated on
                the package.
              </p>
              <p>
                หมายเหตุ: โปรดอ่านรายละเอียดเพิ่มเติม
                <br className="br-sm" />
                ในเอกสารกำกับยาฉบับสมบูรณ์
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default SectionProduct
