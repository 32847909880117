import React, { useState } from "react"
import ReactPlayer from "react-player"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { LazyLoadImage } from "react-lazy-load-image-component"

const video = {
  src: "https://www.youtube.com/watch?v=cqyavo7q7Do",
  // src: "https://vimeo.com/358601660",
  thumbnail: "methycobalvideothumbnail.jpg",
}

const ToggleVideo = ({ handleClick = () => {} }) => {
  const [show, setShow] = useState(false)

  const displayVideo = () => {
    setShow(!show)

    if (typeof window !== "undefined") {
      document.getElementsByTagName("html")[0].className = !show
        ? "disable-scroll"
        : ""
    }
  }

  return (
    <>
      <button
        className="toggle-video defered-bg"
        onClick={() => {
          displayVideo()
          handleClick()
        }}
      >
        <LazyLoadImage
          className="img-fluid"
          src="/iconmascotclickvedio.png"
          loading="lazy"
          alt="Display Video"
        />
      </button>
      <button
        className={`close-full-video ${show ? "" : "hide"}`}
        onClick={() => {
          displayVideo()
          handleClick()
        }}
      >
        <FontAwesomeIcon icon={faTimes} />
      </button>
      <div className={`fullscreen-video ${show ? "" : "hide"}`}>
        <ReactPlayer
          className="slide-video"
          width="100%"
          height="100%"
          loop={true}
          controls={false}
          url={video.src}
          playing={show}
        />
        <LazyLoadImage
          className={`img-fluid video-thumbnail ${show ? "played" : ""}`}
          src={video.thumbnail}
          loading="lazy"
          alt="Methycobal"
        />
      </div>
    </>
  )
}

export default ToggleVideo
