import React from "react"
import { Button } from "reactstrap"

// import "./nav-control.css"

const NavControl = ({ state = "inactive", handleClick = () => {} }) => {
  return (
    <Button
      className={`fixed-top ${state}`}
      id="nav-control"
      onClick={() => handleClick()}
    >
      <span className="box-menu-icon"></span>
    </Button>
  )
}

export default NavControl
