import React from "react"

const SectionContact = () => (
  <footer>
    <div className="footer-container container p-5 px-md-0 px-lg-5 px-sm-5">
      <div className="row">
        <div className="col-md-3 col-sm-12 text-left">
          <div className="footer-box">
            <h4 className="font-bold">Link</h4>
            <ul>
              <li>
                <a href="#product">ผลิตภัณฑ์เมทิโคบอล</a>
              </li>
              <li>
                <a href="#distribute">สถานที่จัดจำหน่าย</a>
              </li>
              <li>
                <a href="#contact">ติดต่อเรา</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-md-4 col-sm-12 text-left">
          <div className="footer-box">
            <h4 className="font-bold">เอกสารอ้างอิง</h4>
            <p>
              Methycobal package insert.
              <br />
              Revised: June 2007 <br className="br-md" />
              (9th Version)
              <br />
              MBLT(RTH).ETM/04.14T
            </p>
          </div>
        </div>
        <div className="col-md-5 col-sm-12 text-left">
          <div className="footer-box">
            <h4 className="font-bold">ผลิตโดย</h4>
            <p>
              Bushu Pharmaceuticals Ltd.
              <br className="br-md" /> Misato Factory
              <br className="br-sm br-md br-ex" /> 950, Hiroki, Ohaza,
              Misato-mashi, Kodama-gun, <br className="br-sm br-ex" />
              Saitama-ken, Japan
            </p>
          </div>
          <div className="footer-box mb-0 sm-fix-width">
            <h4 className="font-bold">แบ่งบรรจุโดย</h4>
            <p>
              บริษัท อินเตอร์ไทย ฟาร์มาซูติเคิ้ล
              <br className="br-md" /> แมนูแฟคเจอริ่ง จำกัด
              <br />
              1899 ถนนพหลโยธิน แขวงลาดยาว
              <br className="br-md" /> เขตจตุจักร กรุงเทพมหานคร 10900 ประเทศไทย
            </p>
          </div>
        </div>
      </div>
    </div>
    <div className="container-fluid">
      <div className="row">
        <div className="offset-md-3 col-md-6 ">
          <p className="copyright center">
            Copyright ©2019 All rights reserved
          </p>
          <p className="gov-data">
            <small className="pt-1">ใบอนุญาตโฆษณาเลขที่ ฆท.1328/2562</small>
            <small className="pb-1">TH-OMB-GD-19E-01</small>
          </p>
        </div>
      </div>
    </div>
  </footer>
)

export default SectionContact
