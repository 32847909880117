import React from "react"
import { LazyLoadImage } from "react-lazy-load-image-component"

const distributors = [
  "distributors/icon-yaneng.png",
  "distributors/icon-fascino.png",
  "distributors/icon-jakpach.png",
  "distributors/icon-yapaipul.png",
  "distributors/icon-icare.png",
  "distributors/icon-vitaminclub.png",
  "distributors/icon-superdrug.png",
  "distributors/icon-pharmax.png",
  "distributors/icon-voramit.png",
  "distributors/icon-patchpharmacy.png",

  "distributors/icon-pabopharmacy.png",
  "distributors/icon-chareanpharmacy.png",
  "distributors/icon-bkkpharmacy.png",
  "distributors/icon-boots.png",
  "distributors/icon-siriyapharma.png",
  "distributors/icon-savedrug.png",
  "distributors/icon-healthup.png",
  "distributors/icon-safesave.png",
  "distributors/icon-wellek.png",
  "distributors/icon-lappharmacy.png",
  "distributors/icon-sromedical.png",
  "distributors/icon-chatchai.png",
  "distributors/icon-darapharmacy.png",
  "distributors/icon-medlife.png",
  "distributors/icon-healthplus.png",
  "distributors/icon-bebetterpharma.png",
  "distributors/icon-banya.png",
  "distributors/icon-thungklupharmacy.png",
  "distributors/icon-rxphamacy.png",
]

const totalDistributors = distributors.length

const SectionDistribute = () => (
  <section className="box-distribute container-fluid pt-4 pb-5" id="distribute">
    <h2 className="center header-methycobal">สถานที่จัดจำหน่าย</h2>
    <div className="row px-3 py-4">
      {distributors.map((imgPath, i) => (
        <div
          className={
            i + 1 < totalDistributors
              ? `col-md-2 col-4 mb-4`
              : `col-md-5 col-sm-12 mb-4`
          }
          key={i}
        >
          <div className="distributor-logo">
            <LazyLoadImage
              src={imgPath}
              loading="lazy"
              alt="Methycobal distributor"
            />
          </div>
        </div>
      ))}
    </div>
  </section>
)

export default SectionDistribute
