import React from "react"
import { Nav, NavItem } from "reactstrap"
import { LazyLoadImage } from "react-lazy-load-image-component"

const NavContainer = ({ state = "inactive", handleClick = () => {} }) => {
  return (
    <div className={`fixed-top ${state}`} id="nav-container">
      <Nav className="flex-column">
        <NavItem>
          <a
            href="#product"
            className="gw"
            onClick={e => {
              e.preventDefault()
              handleClick()
              setTimeout(() => {
                document.querySelector("#product").scrollIntoView({
                  behavior: "smooth",
                })
              }, 500)
            }}
          >
            ผลิตภัณฑ์เมทิโคบอล
          </a>
        </NavItem>
        <NavItem>
          <a
            href="#distribute"
            className="gw"
            onClick={e => {
              e.preventDefault()
              handleClick()
              setTimeout(() => {
                document.querySelector("#distribute").scrollIntoView({
                  behavior: "smooth",
                })
              }, 500)
            }}
          >
            สถานที่จัดจำหน่าย
          </a>
        </NavItem>
        <NavItem>
          <a
            href="#contact"
            className="gw"
            onClick={e => {
              e.preventDefault()
              handleClick()
              setTimeout(() => {
                document.querySelector("#contact").scrollIntoView({
                  behavior: "smooth",
                })
              }, 500)
            }}
          >
            ติดต่อเรา
          </a>
        </NavItem>
        <i className="mascot defered-bg">
          <LazyLoadImage
            className={`img-fluid`}
            src="/mascot.png"
            alt="Methycobal Mascot"
          />
        </i>
      </Nav>
      <div className="row">
        <div className="test"></div>
      </div>
    </div>
  )
}

export default NavContainer
