import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Footer from "../components/footer"

import SectionIntro from "../components/sections/intro"
import SectionProduct from "../components/sections/product"
import SectionDistribute from "../components/sections/distribute"
import SectionContact from "../components/sections/contact"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div id="layout">
      <SectionIntro />
      <SectionProduct />
      <SectionDistribute />
      <SectionContact />
      <Footer />
    </div>
  </Layout>
)

export default IndexPage
