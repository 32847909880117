import PropTypes from "prop-types"
import React, { useState } from "react"
import { Container, Navbar } from "reactstrap"
import { LazyLoadImage } from "react-lazy-load-image-component"

import NavControl from "./nav-control"
import NavContainer from "./nav-container"

const Header = ({ siteTitle }) => {
  const [displayNav, setDisplayNav] = useState("inactive")

  const setScrollState = state => {
    if (typeof window !== "undefined") {
      document.getElementsByTagName("html")[0].className =
        state === "active" ? "disable-scroll" : ""
    }
  }

  const setTransformLayout = state => {
    if (typeof window !== "undefined") {
      document.querySelector("#layout").className =
        state === "active" ? "active" : ""
    }
  }

  return (
    <Navbar id="navbar" className="bg-oppa" light expand="md" fixed="top">
      <Container fluid>
        <div className="navbarbrand">
          <a href="#intro" className="gw">
            <LazyLoadImage
              src={"logo.png"}
              loading="lazy"
              className="d-inline-block align-top"
              alt="Eisai | Methycobal"
              style={{ marginBottom: "0" }}
            />
            <h1>{siteTitle}</h1>
          </a>
        </div>
        <NavControl
          state={displayNav}
          handleClick={() => {
            const newState = displayNav === "active" ? "inactive" : "active"
            setDisplayNav(newState)
            setScrollState(newState)
            setTransformLayout(newState)
          }}
        />
        <NavContainer
          state={displayNav}
          handleClick={() => {
            setDisplayNav("inactive")
            setScrollState("inactive")
            setTransformLayout("inactive")
          }}
        />
      </Container>
    </Navbar>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
