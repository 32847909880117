import React, { Component } from "react"
import Swiper from "swiper"
import ReactPlayer from "react-player"
import { LazyLoadImage } from "react-lazy-load-image-component"

import ToggleVideo from "./toggle-video"

const WAITING = 10000
const YOUTUBE_CONFIG = {
  showinfo: 0,
  controls: 0,
  playsinline: 1,
  fs: 0,
  iv_load_policy: 3,
  modestbranding: 1,
}
const sliderOptions = {
  loop: true,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
}

class SectionIntro extends Component {
  player = null
  slider = null
  timeout = null
  videoSource = "youtube"
  slideItems = [
    {
      type: "img",
      src: "bg3.jpg",
    },
    {
      type: "vdo",
      src: "https://www.youtube.com/watch?v=cqyavo7q7Do",
      // src: "https://vimeo.com/358601660",
      thumbnail: "methycobalvideothumbnail.jpg",
    },
    // {
    //   type: "vdo",
    //   src: [
    //     {
    //       src: "http://45.33.57.195/static/vedio/tvc.webm",
    //       type: "video/webm",
    //     },
    //     { src: "http://45.33.57.195/static/vedio/tvc.mp4", type: "video/mp4" },
    //   ],
    // },
    {
      type: "img",
      src: "bg2.jpg",
    },
  ]

  state = {
    vdoPlaying: false,
    vdoMuted: true,
    vdoLoop: false,
    vdoLoaded: false,
    firstBlood: false,
  }

  componentDidMount() {
    this.slider = new Swiper(".swiper-container", sliderOptions)
    this.slider.on("slideChangeTransitionEnd", () => {
      this.handleSlideChange()
    })

    this.showNextSlide()
    this.resizeVdo()
  }

  handleSlideChange() {
    const activeIndex = this.slider.activeIndex
    let index = activeIndex

    if (sliderOptions.loop === true) {
      index = activeIndex - 1
      if (index >= this.slideItems.length) index = 0
      if (index < 0) index = this.slideItems.length - 1
    }

    const currentSlideType = this.slideItems[index].type

    if (this.state.vdoPlaying) {
      this.setState({ vdoPlaying: false })
    }

    clearTimeout(this.timeout)

    switch (currentSlideType) {
      case "img":
        this.showNextSlide()
        break
      case "vdo":
        this.setState({ vdoPlaying: true })
        break
      default:
        throw new Error("invalid slide type")
    }
  }

  handleEndedVideo() {
    this.setState({ vdoPlaying: false })
    this.slider.slideNext()
  }

  handleReady() {
    if (this.state.vdoLoaded) return
    this.setState({ vdoLoaded: true })
  }

  showNextSlide() {
    this.timeout = setTimeout(() => {
      this.slider.slideNext()
    }, WAITING)
  }

  resizeVdo() {
    if (typeof window !== "undefined") {
      if (this.videoSource !== "youtube") {
        Array.from(document.querySelectorAll(".slide-video")).forEach(el => {
          el.style.width = "100%"
          el.style.height = "100%"
        })
        Array.from(document.querySelectorAll(".slide-video video")).forEach(
          el => {
            el.style.width = null
            el.style.height = null
          }
        )
      }
    }
  }

  render() {
    return (
      <section className="box-intro" id="intro">
        <ToggleVideo
          handleClick={() => {
            this.setState({ vdoPlaying: !this.state.vdoPlaying })
          }}
        />
        <a href="#product" className="gw goto-product defered-bg">
          <LazyLoadImage
            className="img-fluid"
            src="/icondown.png"
            loading="lazy"
            alt="ผลิตภัณฑ์เมทิโคบอล"
          />
        </a>
        <div className="swiper-container">
          <div className="swiper-wrapper">
            {this.slideItems.map((item, i) => (
              <div className="swiper-slide" data-type={item.type} key={i}>
                {item.type === "img" && (
                  <>
                    <img
                      className="img-fluid desktop"
                      src={item.src}
                      loading="lazy"
                      alt="Methycobal"
                    />
                  </>
                )}
                {item.type === "vdo" && (
                  <>
                    <strong className="hilight-text">
                      เรื่องชาอย่าปล่อยให้ชิน
                    </strong>
                    <ReactPlayer
                      className="slide-video"
                      url={item.src}
                      playing={this.state.vdoPlaying}
                      loop={this.state.vdoLoop}
                      muted={this.state.vdoMuted}
                      width={`100%`}
                      height={`100%`}
                      controls={false}
                      onEnded={() => {
                        this.handleEndedVideo()
                      }}
                      onReady={() => {
                        this.handleReady()
                      }}
                      onPlay={() => {}}
                      onStart={() => {}}
                      youtubeConfig={{
                        playerVars: YOUTUBE_CONFIG,
                        embedOptions: YOUTUBE_CONFIG,
                      }}
                    />
                    <img
                      className={`img-fluid video-thumbnail ${
                        this.state.vdoPlaying ? "played" : ""
                      }`}
                      src={item.thumbnail}
                      loading="lazy"
                      alt="Methycobal"
                    />
                  </>
                )}
              </div>
            ))}
          </div>
          <div className="swiper-button-prev swiper-button-white"></div>
          <div className="swiper-button-next swiper-button-white"></div>
        </div>
      </section>
    )
  }
}

export default SectionIntro
